.booking-view {
	padding: 120px 0 150px;

	.checkbox {
		label {
			font-weight: normal;
			font-size: 14px;
		}

	}

	.privacy-area {
		a {
			top: 2px !important;

			@media (max-width:1580px) {
				top: 3px !important;
			}
		}
	}

	.form-control {
		border: none;
		border-bottom: 2px solid #f2f2f2;
		text-transform: uppercase;
		margin-bottom: 30px;
		border-radius: 0;
		box-shadow: inherit;
		padding: 0;
	}

	.cart-details {
		background-color: var(--grey4);
		padding: 15px;
		box-shadow: 0 0px 5px rgba(0, 0, 0, .25);
		max-width: 450px;
		margin: 55px 0 20px;

		.price-table {
			.total {
				td {
					font-size: 16px;
					color: var(--secondary);
				}
			}

			td {
				.btn {
					display: none !important;
				}
			}

			.price {
				color: var(--primary) !Important;
			}
		}

		.unit-details {
			margin-top: 10px;
			padding-left: 0;

			li {
				margin-left: 0px;
				color: var(--black);
				position: relative;
				list-style: none;
				padding-left: 25px;
				font-size: 15px;
				margin-bottom: 4px;

				&:before {
					content: "\f00c";
					color: var(--secondary);
					position: absolute;
					left: 0;
					font-family: Font Awesome\ 5 Pro !important;
					top: 0;
				}
			}
		}
	}

	.voucher-bl {
		.panel-title {
			text-transform: uppercase;
			font-size: 14px;

		}

		.pl-0 {
			@media (min-width:768px) {
				padding-left: 0;
			}
		}

		.input-check-bl {
			@media (min-width:768px) {
				display: flex;
				justify-content: space-between;
			}

			.form-control {
				@media (min-width:768px) {
					margin-right: 10px;
				}

				margin-bottom: 10px;
				height: 45px;
			}
		}
	}

	.pd-form {
		label {
			font-weight: normal;
			text-align: left;
			font-weight: 700;
			text-transform: uppercase;
		}

		label.lrequired {
			font-weight: 700;
		}

	}

	.final-col {
		.checks {
			margin-bottom: 30px;

		}

		margin-top: 10px;
		margin-bottom: 20px;
	}

	.flow-btn {
		margin-left: 20px;
		margin-bottom: 20px;
	}


	.pay-online-box {
		min-height: 240px;

		.amount-options-list {
			padding-left: 10px;
		}

		.amount-option-btn {
			list-style-type: none;
		}

		.amount-option {
			border: none;
			font-weight: bold;
			padding: 0;
			margin-bottom: 4px;
			margin-left: 10px;
		}

		.amount-or {
			margin-top: 2px;
			font-weight: normal;
			font-style: italic;
		}

		.bank-detail-box {
			padding-left: 10px;
		}
	}


}