@import "setup.css";

.indexpage {


	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}



	.banner-forms {
		position: relative;
		min-height: 620px;
		height: 100vh;
		/* z-index: 3; */
		z-index: unset;

		@media (max-width:991px) {
			z-index: unset;
		}

		@media (max-width:767px) {
			min-height: 560px;

		}

		@media (max-width:992px) {
			height: calc(100vh - 80px);
		}

		.nxt-prev-btns {
			position: relative;

			.owl-nav {
				position: absolute;
				top: 40%;
				left: 0;
				width: 100%;

				@media (max-width:992px) {
					display: none;
				}


				button {
					width: 70px;
					height: 70px;
					border-radius: 50%;
					border: 1px solid #ddd !important;
					border-radius: 50%;
					box-shadow: 0 0 10px rgba(0, 0, 0, .5);
					background-color: var(--white) !important;
					position: absolute;
					left: 50px;
					font-size: 34px;

					span {
						display: inline-block;
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
						color: transparent;

						&:before {
							color: var(--black);
							font-family: "Font Awesome 5 Pro";
							position: relative;
							left: 3px;
						}
					}

					&:hover {
						span {
							opacity: .3;
						}
					}

					&.owl-next {
						position: absolute;
						right: 50px;
						left: initial;

						span {

							&:before {
								content: "\f054";
								left: 8px;
							}
						}
					}

					&.owl-prev {
						span {

							&:before {
								content: "\f053";

							}
						}
					}
				}
			}
		}

		&:before {
			background: rgb(255, 255, 255);
			background: linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
			position: absolute;
			left: 0;
			width: 100%;
			height: 250px;
			content: "";
			top: 0;

			@media (max-width:992px) {
				height: 180px;
			}
		}

		&:after {

			background: rgb(255, 255, 255);
			background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
			position: absolute;
			left: 0;
			width: 100%;
			height: 250px;
			content: "";
			bottom: 0;

			@media (max-width:992px) {
				height: 180px;
			}
		}

		@media (max-width:992px) {
			height: calc(100vh - 80px);
		}

		.search-form-area {
			display: flex;
			align-items: center;
			justify-content: space-between;

			@media (max-width:1340px) {
				flex-wrap: wrap;
			}

			@media (min-width:1141px) {
				padding-left: 15px;
			}

			.form-flex {
				padding: 15px 8px 5px;


				@media (max-width:1140px) {
					width: 100%;
					padding: 10px 0;
				}

				&:first-child {
					border-left: none;
				}

				.form-bx-area {
					position: relative;
					padding-right: 105px;
					padding-left: 2px;
					border-bottom: 2px solid var(--grey);

					.box {
						.asd__wrapper {
							@media (max-width: 480px) {
								margin: 0 auto;
								padding-top: 0;
							}
						}
					}

					.datepicker-ext-row {
						@media (max-width: 767px) {
							flex-wrap: wrap;
							align-items: center;
							justify-content: center;
							max-width: 280px;
							margin: 35px auto 0 !important;

						}

						select {
							margin: 0 20px 10px 10px;

							@media (max-width: 767px) {
								margin-right: 0;
							}
						}

					}


					&.custom-select {
						&:after {
							content: "";
							width: 15px;
							height: 15px;
							position: absolute;
							background-color: var(--white);
							top: 34px;
							right: 90px;

							@media (max-width:1340px) {
								top: 20px;
								right: 60px;
							}

							@media (max-width:1140px) {
								top: 15px;
								right: 57px;
							}
						}
					}

					&.pinarea {
						@media (min-width:1240px) {
							padding-right: 55px;
							min-width: 330px;
						}
					}

					@media (max-width:1340px) {
						padding-right: 85px;
					}

					button.form-control {
						min-width: 230px;

						@media (max-width:1340px) {
							font-size: 18px;
						}
					}


				}

				.fa,
				.far {
					position: absolute;
					right: 2px;
					top: 44px;
					color: var(--black);
					z-index: 2;
					font-size: 20px;
					pointer-events: none;

					@media (max-width:1340px) {
						top: 28px;
					}

					@media (max-width:1140px) {
						top: 13px;

					}

					&.shadow {
						font-size: 65px;
						top: 0px;
						color: var(--grey);
						z-index: 1;
						right: 14px;

						@media (max-width:1340px) {
							font-size: 48px;
						}

						@media (max-width:1140px) {
							font-size: 37px;
							top: -4px;
							opacity: .5;
						}
					}

					&.fa-map-marker-alt {
						right: 8px;

						@media (max-width:1140px) {
							right: 0;
						}

						&.shadow {
							@media (max-width:1140px) {
								right: 9px !important;
							}
						}
					}
				}


				.form-control {
					background-color: var(--white);
					border: none;
					border-radius: 0;
					width: 100%;
					padding: 28px 0 15px;
					padding-right: 10px;
					padding-left: 10px;
					height: inherit;
					box-shadow: inherit;
					font-family: var(--dfont2);
					padding: 40px 0 11px;
					font-size: 21px;
					text-align: left;


					@media (max-width:1340px) {
						font-size: 18px;
						padding: 20px 0 11px;
					}

					@media (max-width:1140px) {
						font-size: 16px !important;
						padding: 10px 0;
					}
				}

				#bedrooms {
					padding-left: 5px !important;
				}


			}

			.form-btn {

				@media (max-width:1140px) {
					width: 100%;
					text-align: center;
				}

				.btn {
					align-items: center;
					display: flex;
					justify-content: center;
					font-size: 30px;
					border-radius: 0 8px 8px 0;
					font-family: var(--dfont2);
					font-weight: bold;
					height: 120px;
					position: relative;
					margin-left: 15px;

					@media (max-width:1340px) {
						font-size: 24px;
						height: 100px;

					}


					@media (max-width:1140px) {
						font-size: 16px;
						border-radius: 4px;
						position: relative;
						height: inherit;
					}

					i {
						margin-left: 8px;
						color: rgba(0, 0, 0, .25);
						font-size: 30px;
						margin-right: 12px;

						@media (max-width:1140px) {
							font-size: 16px;
							margin-left: 0;
						}
					}


				}
			}
		}

		.banner-slide {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			z-index: -1;

			.owl-dots {
				display: none;
			}

			.owl-item {
				position: relative;
			}

			.item {
				width: 100%;
				min-height: 620px;
				height: 100vh;
				background-size: cover;
				background-position: no-repeat;
				background-position: center;

				@media (max-width:992px) {
					height: calc(100vh - 80px);
				}

				@media (max-width:767px) {
					min-height: 560px;
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

				&.banner1 {
					background-image: url(RESOURCE/img/banner1-1.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/mob-banner1-1.jpg);
					}
				}

				&.banner2 {
					background-image: url(RESOURCE/img/banner2-2.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/mob-banner2-2.jpg);
					}
				}

				&.banner3 {
					background-image: url(RESOURCE/img/banner3-3.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/mob-banner3-3.jpg);
					}
				}

				&.banner4 {
					background-image: url(RESOURCE/img/banner4-4.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/mob-banner4-4.jpg);
					}
				}

				&.banner5 {
					background-image: url(RESOURCE/img/banner5-5.jpg);

					@media (max-width:992px) {
						background-image: url(RESOURCE/img/mob-banner5-5.jpg);
					}
				}

				&:after {
					height: 80px;
					bottom: 0;
					left: 0;
					right: 0;
					position: absolute;
					display: block;
					background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(255, 255, 255, 0.5) 70%, rgba(255, 255, 255, 0.8) 100%);
					background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(255, 255, 255, 0.5) 70%, rgba(255, 255, 255, 0.8) 100%);
					background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(255, 255, 255, 0.5) 70%, rgba(255, 255, 255, 0.8) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#cc000000', GradientType=0);
					content: "";
					opacity: 0.6;
				}
			}


			.owl-dots {
				bottom: 60px;
				width: 50%;
				left: 50%;
				transform: translate(-50%, 0);
				font-size: 27px;
				text-align: center;
				position: absolute;

				@media (max-width:992px) {
					display: none;
				}

				.owl-dot {
					width: 50px;
					height: 0;
					display: inline-block;
					border-bottom: 4px solid var(--white);
					margin: 0 4px;

					&.active {
						border-bottom-color: var(--primary);
					}
				}
			}
		}


		.search-box-home {
			position: absolute;
			bottom: 165px;
			text-align: center;
			width: 100%;
			z-index: 5;
			padding: 0 50px;

			@media a;

			ll a() select {
				/* for Firefox */
				-moz-appearance: none;
				/* for Chrome */
				-webkit-appearance: none;
			}

			/* For IE10 */
			select::-ms-expand {
				display: none;
			}

			@media (max-width:1140px) {
				bottom: 18px;
				padding: 0 20px;
			}
		}

		.searchbar {

			display: inline-block;
			text-align: left;
			background-color: var(--white);
			border-radius: 8px;

			@media (max-width:1140px) {
				width: 100%;
				padding: 10px 20px 10px;
			}

			/*@media (min-width:993px) {
				max-width: 1200px;
				width: 100%;
			}*/


			.searchbox {
				padding: 0;
				position: relative;
				right: -1px;


				@media (max-width:1140px) {
					padding-top: 10px;
					display: inline-block;
					right: 0;
				}

				input,
				.search-btn,
				select {
					border-radius: 0;
				}

				.search-btn {
					width: 100%;
				}

				.search-popup-trigger {
					cursor: pointer;
				}

				.form-control:disabled,
				.form-control[readonly] {
					background-color: white;
				}
			}


		}

		.contact-section {
			background-color: #fff !important;
		}






		@media (max-width:767px) {

			.iheader {
				background: var(--introMobileImg) no-repeat center;
				-webkit-background-size: cover;
				background-size: cover;

				.intro {
					.hero {
						h2 {
							font-size: 18px;
						}
					}
				}


			}
		}


	}


	.covid-section {
		margin: 0;
		position: absolute;
		bottom: 20px;
		right: 20px;
		z-index: 1;

		@media (max-width: 1140px) {
			right: 20px;
			top: 110px;
			bottom: inherit;
		}

		.covid-btn {
			width: 140px;
			height: 140px;
			border-radius: 100px;
			background-color: var(--primary);
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			text-align: center;
			font-size: 16px;
			padding: 15px 15px;

			@media (max-width: 1140px) {
				width: 110px;
				height: 110px;
				font-size: 14px;
				padding: 6px 10px;
			}

			@media all and (min-width: 1141px) and (max-width: 1170px) {
				width: 130px;
				height: 130px;

			}

			&:hover {
				background-color: var(--secondary);
			}
		}
	}
}