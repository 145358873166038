@import "font-awesome.css";


@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}


@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

blockquote {
	border-left-color: var(--secondary);

	footer {
		color: var(--secondary);
	}
}

.flex {
	@mixin flex;
}

a {
	&:hover {
		text-decoration: none;
	}
}


@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}


@font-face {
	font-family: 'Julius Sans One';
	src: url('RESOURCE/fonts/JuliusSansOne-Regular.eot');
	src: url('RESOURCE/fonts/JuliusSansOne-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/JuliusSansOne-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/JuliusSansOne-Regular.woff') format('woff'),
		url('RESOURCE/fonts/JuliusSansOne-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/JuliusSansOne-Regular.svg#JuliusSansOne-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Ubuntu';
	src: url('RESOURCE/fonts/Ubuntu-Bold.eot');
	src: url('RESOURCE/fonts/Ubuntu-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Ubuntu-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/Ubuntu-Bold.woff') format('woff'),
		url('RESOURCE/fonts/Ubuntu-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/Ubuntu-Bold.svg#Ubuntu-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Ubuntu';
	src: url('RESOURCE/fonts/Ubuntu-Light.eot');
	src: url('RESOURCE/fonts/Ubuntu-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Ubuntu-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/Ubuntu-Light.woff') format('woff'),
		url('RESOURCE/fonts/Ubuntu-Light.ttf') format('truetype'),
		url('RESOURCE/fonts/Ubuntu-Light.svg#Ubuntu-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Ubuntu';
	src: url('RESOURCE/fonts/Ubuntu-Medium.eot');
	src: url('RESOURCE/fonts/Ubuntu-Medium.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Ubuntu-Medium.woff2') format('woff2'),
		url('RESOURCE/fonts/Ubuntu-Medium.woff') format('woff'),
		url('RESOURCE/fonts/Ubuntu-Medium.ttf') format('truetype'),
		url('RESOURCE/fonts/Ubuntu-Medium.svg#Ubuntu-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Ubuntu';
	src: url('RESOURCE/fonts/Ubuntu-Regular.eot');
	src: url('RESOURCE/fonts/Ubuntu-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Ubuntu-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Ubuntu-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Ubuntu-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/Ubuntu-Regular.svg#Ubuntu-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito';
	src: url('RESOURCE/fonts/Nunito-ExtraLight.eot');
	src: url('RESOURCE/fonts/Nunito-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Nunito-ExtraLight.woff2') format('woff2'),
		url('RESOURCE/fonts/Nunito-ExtraLight.woff') format('woff'),
		url('RESOURCE/fonts/Nunito-ExtraLight.ttf') format('truetype'),
		url('RESOURCE/fonts/Nunito-ExtraLight.svg#Nunito-ExtraLight') format('svg');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito';
	src: url('RESOURCE/fonts/Nunito-Regular.eot');
	src: url('RESOURCE/fonts/Nunito-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Nunito-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Nunito-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Nunito-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/Nunito-Regular.svg#Nunito-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito';
	src: url('RESOURCE/fonts/Nunito-SemiBold.eot');
	src: url('RESOURCE/fonts/Nunito-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Nunito-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/Nunito-SemiBold.woff') format('woff'),
		url('RESOURCE/fonts/Nunito-SemiBold.ttf') format('truetype'),
		url('RESOURCE/fonts/Nunito-SemiBold.svg#Nunito-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito';
	src: url('RESOURCE/fonts/Nunito-Bold.eot');
	src: url('RESOURCE/fonts/Nunito-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Nunito-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/Nunito-Bold.woff') format('woff'),
		url('RESOURCE/fonts/Nunito-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/Nunito-Bold.svg#Nunito-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Indie Flower';
	src: url('RESOURCE/fonts/IndieFlower.eot');
	src: url('RESOURCE/fonts/IndieFlower.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/IndieFlower.woff2') format('woff2'),
		url('RESOURCE/fonts/IndieFlower.woff') format('woff'),
		url('RESOURCE/fonts/IndieFlower.ttf') format('truetype'),
		url('RESOURCE/fonts/IndieFlower.svg#IndieFlower') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Font Awesome 5 Free';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}

.far {
	font-family: 'Font Awesome 5 Free';
	font-weight: 400;
}

@font-face {
	font-family: 'Font Awesome 5 Free';
	font-style: normal;
	font-weight: 900;
	font-display: block;
	src: url("RESOURCE/fonts/fa-solid-900.eot");
	src: url("RESOURCE/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-solid-900.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-solid-900.woff") format("woff"),
		url("RESOURCE/fonts/fa-solid-900.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-solid-900.svg#fontawesome") format("svg");
}

.fa,
.fas {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
}

@font-face {
	font-family: 'Font Awesome 5 Brands';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-brands-400.eot");
	src: url("RESOURCE/fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-brands-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-brands-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-brands-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-brands-400.svg#fontawesome") format("svg");
}

.fab {
	font-family: 'Font Awesome 5 Brands';
	font-weight: 400;
}


.overflowscrolls {
	@mixin overflowscrolls;
}


.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.wrap-btn {
	white-space: inherit;
	height: auto !important;
}

.text-white {
	color: var(--white);

	&:hover {
		color: var(--secondary);
	}
}

.hidden {
	display: none;
}

.btn-go {
	background-color: #007da7;
	color: #FFF !important;
	border: 1px solid #026B8E;
	//box-shadow: 0 1px 1px #eee;
}


.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: #FFF;
	background-color: #0187B4;
	border: 1px solid #09BDF3;
}


.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.grey-bg {
	border-top: 1px solid #eaebec;
	border-bottom: 1px solid #eaebec;
	background: #F7F8FA;
}

.text-blue {
	color: #337ab7;
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {
	.fa-check {
		color: green;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}


.contact-form {

	label {
		font-weight: bold;
	}

	margin-bottom: 20px;
}

.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}

.asd__day--tada {
	background-color: red;
}

.asd__mobile-close {
	background-color: var(--black);
	color: var(--white);
	width: 36px;
	border-radius: 0;
	margin-right: 10px;
}

.custom-select {
	select {
		padding-left: 5px !important;
		padding-right: 5px;
	}
}

.full-img {
	width: 100%;
}

.cust-select {
	position: relative;

	select {
		cursor: pointer;
		padding: 5px;
	}

	&:after {
		background-color: var(--white);
		width: 15px;
		height: 10px;
		position: absolute;
		content: "";
		right: 3px;
		top: 13px;
	}
}



.datepicker-trigger {
	--cal_not_available: #cd7898;

	.asd__day--not-available {
		background: var(--cal_not_available) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival {
		background: linear-gradient(90deg, white 70%, var(--cal_not_available) 80%) !important;
		opacity: 1 !important;
	}

	.asd__day--departure {
		background: linear-gradient(90deg, var(--cal_not_available) 20%, white 30%) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, #e4e7e7 70%, var(--cal_not_available) 80%) !important;
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, var(--cal_not_available) 20%, #e4e7e7 30%) !important;
	}

	.asd__day--disabled.asd__day--in-range {
		background: var(--primary) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: var(--primary) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		color: rgba(86, 90, 92, 0.5) !important;
	}


	.asd__day--selected {
		background: var(--secondary) !important;
		color: white !important;
		opacity: 1 !important;
		border-color: var(--secondary);
	}

	.asd__day--hovered {
		background: var(--primary) !important;
		opacity: 1 !important;
	}

	.asd__day--in-range {
		background: var(--primary) !important;
	}
}



/*
.pswp {
	display: none;
}
*/


.guestbox {
	td {
		.btn {
			font-family: arial, sans-serif;
		}
	}
}

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

@font-face {
	font-family: 'DINRoundPro-Medi';
	src: url('RESOURCE/fonts/DINRoundPro-Medi.eot');
	src: local('RESOURCE/fonts/DINRoundPro-Medi'),
		url('RESOURCE/fonts/DINRoundPro-Medi.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/DINRoundPro-Medi.woff2') format('woff2'),
		url('RESOURCE/fonts/DINRoundPro-Medi.woff') format('woff'),
		url('RESOURCE/fonts/DINRoundPro-Medi.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'DINRoundPro';
	src: url('DRESOURCE/fonts/INRoundPro.eot');
	src: local('RESOURCE/fonts/DINRoundPro'),
		url('RESOURCE/fonts/DINRoundPro.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/DINRoundPro.woff2') format('woff2'),
		url('RESOURCE/fonts/DINRoundPro.woff') format('woff'),
		url('RESOURCE/fonts/DINRoundPro.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}



@font-face {
	font-family: 'DINRoundPro';
	src: url('RESOURCE/fonts/DINRoundPro-Bold.eot');
	src: local('RESOURCE/fonts/DINRoundPro-Bold'),
		url('RESOURCE/fonts/DINRoundPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/DINRoundPro-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/DINRoundPro-Bold.woff') format('woff'),
		url('RESOURCE/fonts/DINRoundPro-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

.fal {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 300;
}

.far {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 400;


	&.fa-container-storage:before {
		content: "\f4b7";
	}

	&.cross {
		position: relative;

		&:after {
			content: "";
			width: 2px;
			height: 100%;
			background-color: rgba(0, 0, 0);
			position: absolute;
			transform: rotate(-45deg);
			z-index: 2;
			left: 9px;
			top: 1px;
		}
	}

	&.fa-umbrella-beach:before {
		content: "\f5ca";
	}
}

.fa {


	&.fa-container-storage:before {
		content: "\f4b7";
	}

	&.cross {
		position: relative;

		&:after {
			content: "";
			width: 1.5px;
			height: 100%;
			background-color: var(--d-grey);
			position: absolute;
			transform: rotate(-45deg);
			z-index: 2;
			left: 12px;
			top: 1px;
		}
	}

	&.fa-umbrella-beach:before {
		content: "\f5ca";
	}
}

body {
	font-family: var(--d-font);

	color: var(--black);
	font-size: 14px;

	@media (max-width:1580px) {
		font-size: 13px;
	}
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 300;
	font-display: block;
	src: url("RESOURCE/fonts/fa-light-300.eot");
	src: url("RESOURCE/fonts/fa-light-300.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-light-300.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-light-300.woff") format("woff"),
		url("RESOURCE/fonts/fa-light-300.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-light-300.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}

.container-fluid {
	width: 100%;
}

.hide-mob {
	@media (min-width:993px) {
		display: block !important;
	}

	@media (max-width:992px) {
		display: none !important;
	}


}

.show-mob {
	@media (max-width:992px) {
		display: block !important;
	}

	@media (min-width:993px) {
		display: none !important;
	}
}

a,
input,
button,
.btn,
*,
a:focus,
input:focus,
button:focus,
.btn:focus,
.checkbox input[type=checkbox]:focus+label:before {
	outline: none !important;
	outline-offset: 0 !important;
	text-decoration: none;
}

h1,
h2 {
	font-family: var(--h1-font);
}

h3 {
	font-size: 24px;
	font-weight: bold;
}

h2 {
	@media (max-width:767px) {
		font-size: 24px;
	}
}

h4 {
	font-size: 18px;
	font-weight: bold;
}

h5 {
	font-size: 16px;
}

.pad75 {
	padding: 50px 0 30px;

	@media (max-width:992px) {
		padding: 30px 0 20px;
	}
}

ul {
	padding: 0;
	margin: 0;
}

.container {
	@media (min-width: 1340px) {
		width: 1240px;
	}
}

.txt-blue {
	color: var(--secondary);
}

.pb-130 {
	padding-bottom: 130px !Important;
}

.pb-240 {
	padding-bottom: 180px !Important;
}

.btn {
	border-radius: 3px;
	font-size: 16px;
	padding: 15px 18px;

	@media (max-width:992px) {
		font-size: 14px;
		padding: 6px 10px;
	}

	&.btn-primary,
	&.btn-go,
	&.btn-warning {
		background-color: var(--primary);
		color: var(--white);
		border-color: var(--primary);

		&:hover {
			background-color: var(--secondary);
			border-color: var(--secondary);
		}

		&.btn-sm {
			font-size: 14px;
			padding: 8px 12px;
		}
	}

	&.btn-default {
		color: var(--black);
		border-color: var(--primary);

		&.btn-sm {
			font-size: 14px;
		}

		&:hover {
			border-color: var(--primary);
			background-color: var(--primary);
			color: var(--white);
		}
	}

	&.btn-sm {
		font-size: 16px;
		padding: 6px 12px;
	}

	&.btn-secondary {
		background-color: var(--secondary);
		color: var(--white);
		border-color: var(--secondary);

		&:hover {
			background-color: var(--primary);
			border-color: var(--primary);
			color: var(--white);

		}
	}

	&.btn-lg {
		height: 46px;
		font-size: 18px;
		padding: 10px 16px;
	}

	&.big-pad {
		padding: 8px 30px;
	}

	&:focus {
		outline: 0 !important;
		text-decoration: none !important;
	}

	&.btn-arrow {
		min-width: 220px;
		display: inline-flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width:992px) {
			min-width: 160px;
		}

		.far {
			opacity: .4;
			margin-left: 15px;
		}
	}
}

.form-control:focus {
	box-shadow: inherit;
	outline: none;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.min-row {
	margin: 0 -10px;

	.col-md-7,
	.col-lg-4,
	.col-md-5,
	.col-md-6,
	.col-md-8,
	.col-md-4,
	.col-md-9,
	.col-md-3,
	.col-sm-7,
	.col-sm-5 {
		padding: 0 10px;
	}
}

.asd__wrapper--full-screen {
	z-index: 5 !important;
}

.search-bar,
.search-form-area {
	.datepicker-trigger .asd__wrapper {
		@media (max-width:767px) {
			padding-top: 0;
		}
	}

	.datepicker-trigger .asd__wrapper,
	.box.full-xxs {
		@media (max-width:480px) {
			padding-top: 0;
			margin: 0 auto;
		}

		.body {
			padding-top: 30px;
		}

		.header {
			.close-btn {
				margin: 0;
				position: absolute;
				top: 5px;
				right: 5px;
				width: 20px;
				height: 20px;
				color: #fff !important;
				background-color: var(--primary);
				border-radius: 3px;
				font-size: 12px;
				display: flex;
				align-items: center;
				justify-content: center;

				&:hover {
					background-color: var(--secondary);
				}

				.fa.fa-times {
					position: static !important;
					font-size: 12px !important;
					color: #fff !important;
				}
			}

		}


		@media (min-width:481px) {
			min-width: 320px;
		}
	}
}

.flex-sp-between {
	display: flex;
	justify-content: space-between;
}

.flex-align-center {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.single-unit {
	border-radius: 4px;
	width: 100%;
	overflow: hidden;
	padding: 0;
	margin: 0 auto 10px;
	max-width: 500px;
	transition: all 0.25s ease 0s;



	.unit-img {
		position: relative;
		border-radius: 8px;
		display: block;
		box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
		overflow: hidden;

		img {
			width: 100%;
		}

		.fav-element {
			position: absolute;
			width: 40px;
			color: var(--white);
			height: 40px;
			right: 10px;
			top: 10px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-size: 24px;
			transition: all 0.4s;
			cursor: pointer;
		}

		.udetails {
			left: 10px;
			top: 10px;

			>span {
				line-height: 2;
				display: inline-block;
				margin-right: 5px;
				padding: 0 10px;
				background-color: rgba(153, 153, 153, .8);
				font-size: 12px;
				color: var(--white);
			}

			position: absolute;
		}
	}

	.unit-cont-bl {
		padding: 15px;
		position: relative;
		margin: 0 25px;
		background-color: var(--white);
		top: -35px;
		border-radius: 5px;
		box-shadow: 0 2px 6px rgba(0, 0, 0, .2);

		@media (max-width:992px) {
			margin: 0 10px;
		}

		@media (max-width:992px) {
			padding: 15px 10px;
		}


		.flex-sp-between {
			min-height: 40px;
		}

		.unit-title {
			color: var(--black);
			font-size: 16px;
			margin: 0;
			padding-bottom: 8px;
			min-height: 45px;

			&:hover {
				color: var(--primary);
			}
		}

		.unit-distance {
			padding: 0 15px;
			font-weight: bold;
			right: 0;
			top: 0;
			font-size: 12px;
			line-height: 30px;
			color: rgba(0, 0, 0, .7);
			background-color: var(--white);
			border-radius: 15px;
			position: absolute;
			top: -55px;
		}

		.unit-region {
			color: var(--secondary);
			font-size: 13px;
			font-family: var(--d-font2);
			margin: 0 0 15px;
		}

		.unit-link {
			text-transform: uppercase;
			color: var(--primary);
			font-size: 12px;
			font-weight: bold;
			position: relative;
			top: 9px;

			&:hover {
				color: var(--secondary);
			}
		}

		.unit-place {
			font-size: 16px;
			font-family: var(--d-font2);

			li {
				display: inline-block;
				position: relative;
				padding-left: 10px;
				margin-left: 5px;
				color: var(--black);

				@media (max-width:360px) {
					font-size: 14px;
					padding-left: 3px;
				}

				&:first-child {
					padding-left: 0;
					margin-left: 0;

				}

				i {
					font-size: 18px;
					opacity: .2;
					margin-right: 3px;

					@media (max-width:360px) {
						font-size: 15px;
						margin-right: 2px;
					}
				}
			}

		}

		.unit-type {
			font-size: 11px;
			line-height: 1;

			li {
				display: inline-block;
				position: relative;
				padding-left: 10px;
				margin-left: 5px;

				&:first-child {
					list-style: none;
					margin-left: 0;
					padding-left: 0;

					&:before {
						display: none;
					}
				}

				&:before {
					width: 5px;
					height: 5px;
					border-radius: 50%;
					background-color: #d1c7c7;
					position: absolute;
					left: -2px;
					content: "";
					top: 3px;
				}
			}
		}



		.search-result-properties {

			li {
				display: inline-block;
				list-style: none;
				text-align: center;
				float: left;
				padding: 0 0 0 10px;

				&:first-child {
					padding-left: 0;
				}

				@media (max-width:360px) {
					padding-left: 5px;
				}

				i {
					font-size: 16px;
					color: var(--d-grey);
					font-style: normal;
					width: 28px;
					height: 28px;
					background-color: var(--grey);
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;

					@media (max-width:360px) {
						font-size: 14px;
						width: 26px;
						height: 26px;
					}

					&.cross {
						&:after {
							width: 1.5px;
							height: 100%;
							position: absolute;
							content: "";
							background-color: var(--d-grey);
							-webkit-transform: rotate(-45deg);
							transform: rotate(-45deg);
						}
					}

				}
			}
		}

		.unit-dt {
			p {
				margin: 0;

				strong {
					font-size: 18px;
					font-weight: 600;
					color: var(--blue3);
				}

				span {
					font-size: 12px;
					color: #7e7e7e;
					display: block;
					line-height: 18px;
				}
			}

			a {
				color: var(--black);
			}

			.price-info {

				i {
					color: #7f7f7f;
					font-size: 12px;
					margin-left: 5px;
				}
			}
		}

		.unit-price-rat {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			margin: 15px -15px 0;
			padding: 10px 15px 0;
			border-top: 1px solid var(--grey);

			@media (max-width:360px) {
				margin: 15px -10px 0;
				padding: 10px 10px 0;
			}
		}

		.unit-rating {
			font-size: 14px;
			min-height: 22px;
			display: block;

			.stars {
				display: inline-block;
			}

			i {
				color: var(--green);
			}

			span {
				color: var(--black);
			}
		}
	}

}

.flex-row-reverse {
	@media (min-width:993px) {
		flex-direction: row-reverse;
		display: flex;
	}
}

.col-md-5 {
	&.col-md-offset-1 {
		@media (max-width:992px) {
			text-align: center;
		}
	}
}

.form-control {
	&::-webkit-input-placeholder {
		/* Edge */
		color: #555;
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #555;
	}

	&::placeholder {
		color: #555;
	}
}

.flex-same-col {
	@media (min-width:993px) {
		display: -webkit-box;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		flex-wrap: wrap;
	}
}

.tooltip.top .tooltip-arrow {
	border-top-color: var(--grey);
}

.tooltip-inner {
	background-color: var(--grey);
	color: var(--black);
}

.popover {
	border: 1px solid #ddd;
	box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
	border-radius: 5px;

	.arrow {
		border-top-color: #ddd;
	}

	.popover-title {
		padding: 8px 14px 0;
		margin: 0;
		font-size: 13px;
		background-color: transparent;
		border-bottom: none;
		border-radius: 5px 5px 0 0;
		color: var(--secondary);
		font-family: var(--d-font);
	}

	.popover-content {
		ul {

			li {
				list-style: none;
				font-size: 11px;
				font-family: var(--d-font);
				color: #7e7e7e;
			}
		}
	}
}



.tooltip.in {
	opacity: 1;
}

.mob-center {
	@media (max-width:992px) {
		text-align: center;
	}
}

h1 {
	font-size: 40px;
	margin-top: 0;
}

.section-title {
	margin-bottom: 50px;
	max-width: 610px;

	@media (max-width:992px) {
		margin-bottom: 30px;
	}

	/*&.align-left {
		text-align: left;

		@media (max-width:992px) {
			text-align: center;
		}
	}*/

	h5 {
		color: var(--secondary);
		font-family: var(--f-nunito);
		font-weight: 700;
		margin-bottom: 25px;
		display: block;
		line-height: 1.5;
		margin-top: 0;

		@media (max-width:992px) {
			font-size: 14px;
		}
	}

	h1 {
		display: block;

		@media (max-width:992px) {
			font-size: 28px;
		}
	}

	&:after {
		background-color: var(--secondary);
		width: 90px;
		height: 2px;
		border-radius: 5px;
		content: "";
		display: inline-block;
		position: relative;
	}
}

.bg-grey-strip {
	background-color: var(--grey4);
	position: relative;
}

.next-prev-btns {
	position: relative;

	.owl-nav {
		position: absolute;
		top: 40%;
		left: 0;
		width: 100%;

		button {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			border-radius: 50%;
			box-shadow: 0 0 8px rgba(0, 0, 0, .21);
			background-color: var(--primary) !important;
			position: absolute;
			left: 0;

			@media (max-width:992px) {
				left: -6px;
			}

			span {
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				color: transparent;

				&:before {
					color: var(--white);
					font-family: "Font Awesome 5 Free";
					position: relative;
					left: 2px;
					font-size: 16px;
					top: 1px;
				}
			}

			&.owl-next {
				position: absolute;
				right: 0;
				left: initial;

				@media (max-width:992px) {
					right: -6px;
				}

				span {

					&:before {
						content: "\f054";
						left: 3px;
					}
				}
			}

			&.owl-prev {
				span {

					&:before {
						content: "\f053";

					}
				}
			}
		}
	}
}

.pt-0 {
	padding-top: 0 !important;
}

.pad30 {
	padding: 30px 0;
}

.bg-li-blue {
	background-color: var(--li-blue);
}

.link {
	text-transform: uppercase;
	color: var(--primary);
	font-size: 12px;
	font-weight: 700;

	&:hover {
		color: var(--secondary);
	}
}

.pt-m-0 {
	@media (max-width:992px) {
		padding-top: 0 !important;
	}
}

.pad10 {
	padding: 10px 0;
}

p {
	font-family: var(--d-font3);
	color: #666;
	line-height: 1.5;
	margin-bottom: 20px;
	font-size: 16px;

	@media (max-width:992px) {
		font-size: 14px;
	}
}

.checkbox label:before {
	width: 20px;
	height: 20px;
	margin-right: 15px;
}

.checkbox label:after,
.checkbox label:before {
	top: 1px;
}

.mb-20 {
	margin-bottom: 20px;
}

.left-mob-align {
	text-align: left !important;
}

.pad60 {
	padding: 60px 0;

	@media (max-width:550px) {
		padding: 40px 0;
	}
}

.aside-widget {
	padding: 20px 15px;
	background-color: var(--grey4);
	font-size: 13px;

	&:last-child {
		border-bottom: none;
	}

	h3 {
		margin-bottom: 18px;
		margin-top: 0;
	}


}

.contact-bl {
	display: flex;
	margin-bottom: 15px;
	position: relative;
	padding-left: 60px;
	min-height: 40px;
	align-items: center;
	font-size: 18px;
	font-weight: var(--d-font2);

	&:last-child {
		margin-bottom: 0;
	}

	a {
		color: var(--black);

		&:hover {
			color: var(--primary);
		}
	}


	i {
		color: var(--white);
		background-color: var(--secondary);
		padding: 5px;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 0;
		top: 2px;
		box-shadow: 0 0 10px rgba(0, 0, 0, .18);
		font-size: 18px;
	}
}

#back_to_top {
	position: fixed;
	bottom: 50px;
	right: -4px;
	z-index: 50;
	border-radius: 4px;
	border: 1px solid var(--grey2);
	display: flex;
	align-items: center;
	height: 50px;
	padding: 15px 22px;
	font-size: 16px;

	@media all and (min-width: 992px) and (max-width: 1100px) {
		bottom: 15px;
	}

	i {
		margin-right: 10px;
		font-size: 22px;
	}

	&:hover {
		border-color: var(--primary2);
	}

	@media (max-width:992px) {
		right: -54px;

		i {
			margin-right: 20px;
		}
	}
}

.checkbox-success input[type=checkbox]:checked+label:before {
	background-color: var(--primary) !important;
	border-color: var(--primary) !important;
}

.checkbox label:after {
	left: 2px;
}

.asd__mobile-close {
	width: 35px;
	font-size: 24px;
	height: 35px;
	border-radius: 3px;

	.asd__mobile-close-icon {
		position: relative;
		top: -5px;
	}
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
	background-color: var(--primary);
	border-color: var(--primary);
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
	color: var(--secondary);
}

.pagination>li>a,
.pagination>li>span {
	color: var(--primary);
}

.owl-carousel.owl-hidden {
	opacity: 100 !important;
}

.unit-list-section {
	.three-units-bl {
		display: none;

		&:first-child {
			display: block !important;
		}

		&:nth-child(2),
		&:nth-child(3) {
			display: block !important;
		}
	}
}


.three-units {

	.col-md-4,
	.col-sm-4 {
		display: none;

		&:first-child {
			display: block !important;
		}

		&:nth-child(2),
		&:nth-child(3) {
			display: block !important;
		}
	}
}

.section-title.style2 {
	max-width: inherit;
}

.datepicker-ext-row {
	display: flex;
	align-items: center;
	margin: 20px 0 0 0 !important;

	@media (max-width: 767px) {
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		max-width: 300px;
		margin: 35px auto 0 !important;
	}

	span {
		font-size: 15px;

		@media (max-width: 767px) {
			margin: 0 0 15px 0;
			max-width: 90px;
			display: inline-block;
			width: 100%;
		}
	}

	select {
		height: 40px;
		max-width: 130px;
		width: 100%;
		border-radius: 0;
		padding-left: 5px;
		padding-right: 5px;
		margin: 0 20px 0 10px;
		border: 1px solid rgba(0, 0, 0, .2);
		background-position: 96% 16px;

		@media (max-width: 767px) {
			margin: 0 20px 10px 10px;
			max-width: 160px;
		}

		@media all and (min-width: 400px) and (max-width: 480px) {
			max-width: 180px;
		}
	}
}

.calendarlabel {
	color: var(--black);
	font-weight: 700;
	font-size: 14px;
	text-transform: uppercase;
	margin-bottom: 0;
	padding-bottom: 10px;
}

.fa.fa-heart-o {
	font-family: "Font Awesome 5 Free";
	font-weight: 400;
}

.checkbox input[type=checkbox]:checked+label:after {
	font-family: "Font Awesome 5 Free" !important;
	font-weight: 600 !important;
}

.angebote-main {
	.angebote-boxes {
		padding-bottom: 0 !important;

		.row {
			display: flex;
			flex-wrap: wrap;

			.col-sm-6 {
				margin-bottom: 30px;

				>div {
					box-shadow: 0 2px 20px 2px rgb(0 0 0 / 10%);
					-webkit-transition: all .25s ease 0s;
					transition: all .25s ease 0s;

					&:hover {
						.img-thumbnail {
							opacity: 0.5;
						}
					}

				}

				.lt-bl-cont {
					position: relative;
					background: #fff;
					padding: 30px 30px 100px 30px;
					min-height: 350px;

					@media (max-width: 767px) {
						padding: 15px 15px 60px 15px;
						min-height: unset;
					}

					@media all and (min-width: 768px) and (max-width: 991px) {
						padding: 15px 15px 40px;
						min-height: 324px;
					}

					@media all and (min-width: 992px) and (max-width: 1199px) {
						padding: 15px 15px 60px;
						min-height: 400px;
					}

					@media all and (min-width: 1200px) and (max-width: 1350px) {

						min-height: 380px;
					}

					p {
						a {
							word-break: break-all;
						}
					}

					.btn-primary {
						position: absolute;
						bottom: 30px;
						width: 90%;
						border-radius: 0;
						left: 0;
						right: 0;
						margin: 0 auto;

						@media (max-width: 767px) {
							bottom: 15px;
							width: calc(100% - 30px);
						}

						@media all and (min-width: 768px) and (max-width: 991px) {
							bottom: 15px;
							width: calc(100% - 30px);
						}
					}
				}

				.img-thumbnail {
					padding: 0;
					border: none;
					border-radius: 0 0;
					height: 250px !important;
					width: 100% !important;
					object-fit: cover;

				}
			}
		}
	}
}